import { addDays, formatISO, startOfToday } from 'date-fns';

import {
  getErrorKey,
  getErrorStatus,
  HTTP_SERVICE_UNAVAILABLE,
} from '@margobank/components/common/error';
import type { PaginatedList, WrappedList } from '@margobank/components/common/types';
import type {
  AccountDTO,
  AccountLiteDTO,
  LoadAccountsParams,
  UpcomingOperationsStatisticsDTO,
} from '@margobank/components/domain/accounts';
import {
  getAccountsSorted,
  getActiveAccounts,
  isSavingAccount,
} from '@margobank/components/domain/accounts';
import type {
  BeneficiaryDTO,
  BeneficiaryLiteDTO,
  CreateBeneficiariesDTO,
  LoadBeneficiariesParams,
  ParsedBeneficiariesDTO,
} from '@margobank/components/domain/beneficiary';
import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import type { DocumentFolderDTO, DocumentFolderType } from '@margobank/components/domain/documents';
import { DOCUMENT_FOLDER_TYPE } from '@margobank/components/domain/documents';
import type {
  AccountOperationDTO,
  CardDeferredOperationDetailsDTO,
  CommonTransferRequestDetailsDTO,
  ExportOperationsParams,
  LoadBackendCurrentAccountOperationsParams,
  LoanRepaymentOperationDetailsDTO,
  OperationDetailsDTO,
  OperationDTO,
  OperationsExportType,
  OperationType,
  OperationTypesAlias,
} from '@margobank/components/domain/operations';
import {
  hasPendingGroupTransfer,
  requiresFlattenOperationsEndpoint,
} from '@margobank/components/domain/operations/helpers';
import type {
  LoadSecondaryIBANsParams,
  SecondaryIBANDTO,
  SecondaryIBANLiteDTO,
} from '@margobank/components/domain/secondary-ibans';
import { SECONDARY_IBAN_STATUS } from '@margobank/components/domain/secondary-ibans';
import type { UserAccessDTO, UserAccessLiteDTO } from '@margobank/components/domain/user';
import { USER_ACCESS_ROLE } from '@margobank/components/domain/user';
import {
  createFileActionsHook,
  createInfiniteQueryHook,
  createMutationHook,
  createQueryHook,
  extendQueryHook,
  extendQueryHookForAsyncSelect,
  invalidateQueryHooks,
  STALE_TIME_LONG,
} from '@margobank/components/query-helpers';
import { canonicalize } from '@memobank/utils';

import type {
  AccountNumberDTO,
  CompanyDTO,
  CreateCurrentAccountParamsDTO,
  CreateSecondaryIBANDTO,
  CurrentUserAccessInvitationDTO,
  ExchangeRateDTO,
  ExchangeRateRequestDTO,
  GroupOperationStatus,
  GroupTransferOperationStatusDTO,
  LoadAccountNumbersParams,
  OverdraftDTO,
  TransferOperationStatusDTO,
  UpdateCurrentAccountNameParamsDTO,
  UpdateCurrentAccountUsersParamsDTO,
  UpdateCurrentAccountVisibilityParamsDTO,
  UpdateSecondaryIBANParamsDTO,
} from 'app/legacy-customer/types';
import http, { CUSTOMERS_SERVICE_PREFIX, V2_ACCEPT_HEADER_VALUE } from 'common/http';
import { createMutationHookWith2FA } from 'components/SecondFactorValidation';

import { hasPendingOCR, isPendingGroupTransfer } from './helpers';
import { useCustomerStore } from './hooks';

const LOAD_ACCOUNT = 'LOAD_ACCOUNT';
const LOAD_ACCOUNT_NUMBERS = 'LOAD_ACCOUNT_NUMBERS';
const LOAD_ACCOUNTS = 'LOAD_ACCOUNTS';
const LOAD_BENEFICIARIES = 'LOAD_BENEFICIARIES';
const LOAD_BENEFICIARY = 'LOAD_BENEFICIARY';
const LOAD_COMPANY = 'LOAD_COMPANY';
const LOAD_CURRENT_ACCOUNT_EXPORT_TYPE = 'LOAD_CURRENT_ACCOUNT_EXPORT_TYPE';
const LOAD_CURRENT_ACCOUNT_OPERATION = 'LOAD_CURRENT_ACCOUNT_OPERATION';
const LOAD_CURRENT_ACCOUNT_OPERATIONS = 'LOAD_CURRENT_ACCOUNT_OPERATIONS';
const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS';
const LOAD_DOCUMENT_FOLDERS = 'LOAD_DOCUMENT_FOLDERS';
const LOAD_EXCHANGE_RATE = 'LOAD_EXCHANGE_RATE';
const LOAD_GROUP_OPERATIONS = 'LOAD_GROUP_OPERATIONS';
const LOAD_GROUP_TRANSFER_STATUS = 'LOAD_GROUP_TRANSFER_STATUS';
const LOAD_INVITATION = 'LOAD_INVITATION';
const LOAD_OVERDRAFT_PRODUCT = 'LOAD_OVERDRAFT_PRODUCT';
const LOAD_SECONDARY_IBAN = 'LOAD_SECONDARY_IBAN';
const LOAD_SECONDARY_IBANS = 'LOAD_SECONDARY_IBANS';
const LOAD_TRANSFER_STATUS = 'LOAD_TRANSFER_STATUS';
const LOAD_UPCOMING_OPERATIONS = 'LOAD_UPCOMING_OPERATIONS';
const LOAD_UPCOMING_OPERATIONS_STATISTICS = 'LOAD_UPCOMING_OPERATIONS_STATISTICS';
const LOAD_USER_ACCESS = 'LOAD_USER_ACCESS';
const LOAD_USERS = 'LOAD_USERS';

const PENDING_GROUP_TRANSFER_REFRESH_DELAY_MS = 3_000;
const PENDING_OCR_REFRESH_DELAY_MS = 1_000;
const PENDING_PRODUCTS_REFETCH_INTERVAL = 1_000;

export const UPCOMING_OPERATIONS_MAX_DAYS = 30;

type UseCustomerExportFileActions = {
  customerId: string;
  exportParams: ExportOperationsParams;
};

export const useCustomerExportFileActions = createFileActionsHook(
  async ({ customerId, exportParams }: UseCustomerExportFileActions) => {
    const { data } = await http.get<Blob>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/export`,
      {
        responseType: 'blob',
        params: exportParams,
        headers: { Accept: V2_ACCEPT_HEADER_VALUE },
      },
    );
    return data;
  },
);

type UseLoadAccount = {
  accountId: string;
  customerId: string;
};

export const useLoadAccount = createQueryHook(
  LOAD_ACCOUNT,
  async ({ accountId, customerId }: UseLoadAccount) => {
    const { data } = await http.get<AccountDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/accounts/${accountId}`,
    );
    return data;
  },
);

type UseLoadAccounts = {
  customerId: string;
  params?: LoadAccountsParams;
};

export const useLoadAccounts = createQueryHook(
  LOAD_ACCOUNTS,
  async ({ customerId, params }: UseLoadAccounts) => {
    const { data } = await http.get<PaginatedList<AccountLiteDTO>>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/accounts`,
      { params: canonicalize(params) },
    );
    return getAccountsSorted(data.results);
  },
);

type UseLoadAccountsFromIds = {
  accountIds?: string[];
  customerId: string;
};

export const useLoadAccountsFromIds = ({ accountIds, customerId }: UseLoadAccountsFromIds) => {
  const [accounts, accountsQuery] = useLoadAccounts({ customerId });
  const filteredAccounts = accounts?.filter(({ id }) => accountIds?.includes(id));
  return [filteredAccounts, accountsQuery] as const;
};

export const useLoadActiveAccounts = extendQueryHook(useLoadAccounts, {
  select: (accounts = []) => getActiveAccounts(accounts),
});

export const useLoadAccountsBalance = extendQueryHook(useLoadAccounts, {
  select: (accounts = []) => accounts.reduce((sum, account) => sum + account.balance, 0),
});

type UseLoadAccountNumbers = {
  customerId: string;
  params?: LoadAccountNumbersParams;
};

export const useLoadAccountNumbers = createQueryHook(
  LOAD_ACCOUNT_NUMBERS,
  async ({ customerId, params }: UseLoadAccountNumbers) => {
    const { data } = await http.get<PaginatedList<AccountNumberDTO>>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/account_numbers`,
      { params: canonicalize(params) },
    );

    return {
      ...data,
      // Don't return Booster account's IBAN
      results: data.results.filter(
        ({ targetCurrentAccount }) => !isSavingAccount(targetCurrentAccount),
      ),
    };
  },
);

export const useLoadAccountNumbersForAsyncSelect =
  extendQueryHookForAsyncSelect(useLoadAccountNumbers);

type UseLoadBankStatements = {
  currentAccountId: string;
  customerId: string;
};

export const useLoadBankStatements = ({ currentAccountId, customerId }: UseLoadBankStatements) =>
  useLoadDocumentFolders({
    customerId,
    documentType: DOCUMENT_FOLDER_TYPE.CURRENT_ACCOUNT_BANK_STATEMENT,
    productId: currentAccountId,
  });

type UseLoadBeneficiaries = {
  customerId: string;
  params?: LoadBeneficiariesParams;
};

export const useLoadBeneficiaries = createQueryHook(
  LOAD_BENEFICIARIES,
  async ({ customerId, params }: UseLoadBeneficiaries) => {
    const { data } = await http.get<PaginatedList<BeneficiaryLiteDTO>>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/beneficiaries`,
      { params: canonicalize(params) },
    );
    return data;
  },
);

type UseLoadBeneficiary = {
  beneficiaryId: string;
  customerId: string;
};

export const useLoadBeneficiary = createQueryHook(
  LOAD_BENEFICIARY,
  async ({ beneficiaryId, customerId }: UseLoadBeneficiary) => {
    const { data } = await http.get<BeneficiaryDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/beneficiaries/${beneficiaryId}`,
    );
    return data;
  },
);

type UseLoadCompany = {
  customerId: string;
};

export const useLoadCompany = createQueryHook(
  LOAD_COMPANY,
  async ({ customerId }: UseLoadCompany) => {
    const { data } = await http.get<CompanyDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/company`,
    );
    return data;
  },
);

type UseLoadCurrentAccountExportType = {
  customerId: string;
  exportParams: ExportOperationsParams;
};

export const useLoadCurrentAccountExportType = createQueryHook(
  LOAD_CURRENT_ACCOUNT_EXPORT_TYPE,
  async ({ customerId, exportParams }: UseLoadCurrentAccountExportType) => {
    const { data } = await http.get<OperationsExportType>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/export_type`,
      {
        headers: { Accept: V2_ACCEPT_HEADER_VALUE },
        params: canonicalize(exportParams),
      },
    );
    return data;
  },
);

type UseLoadCurrentAccountOperation = {
  currentAccountId: string;
  customerId: string;
  groupTransferRequestId?: string;
  operationId: string;
  operationType?: OperationType;
  userId?: string;
};

export const useLoadCurrentAccountOperation = createQueryHook(
  LOAD_CURRENT_ACCOUNT_OPERATION,
  async ({
    currentAccountId,
    customerId,
    groupTransferRequestId,
    operationId,
    operationType,
    userId,
  }: UseLoadCurrentAccountOperation) => {
    switch (operationType) {
      case 'GROUP_TRANSFER_REQUEST':
      case 'SINGLE_TRANSFER_REQUEST':
        if (userId && groupTransferRequestId) {
          const { data: userTransferRequestDetails } =
            await http.get<CommonTransferRequestDetailsDTO>(
              `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/users/${userId}/group_transfer_requests/${groupTransferRequestId}/transfers/${operationId}`,
            );
          return userTransferRequestDetails;
        } else if (userId) {
          const { data: userTransferRequestDetails } =
            await http.get<CommonTransferRequestDetailsDTO>(
              `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/users/${userId}/transfer_requests/${operationId}`,
            );
          return userTransferRequestDetails;
        } else if (groupTransferRequestId) {
          const { data: transferRequestDetails } = await http.get<CommonTransferRequestDetailsDTO>(
            `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/group_transfer_requests/${groupTransferRequestId}/transfers/${operationId}`,
          );
          return transferRequestDetails;
        } else {
          const { data: transferRequestDetails } = await http.get<CommonTransferRequestDetailsDTO>(
            `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/transfer_requests/${operationId}`,
          );
          return transferRequestDetails;
        }

      case 'LOAN_REPAYMENT_FUTURE':
        const { data: loanRepaymentOperationDetails } =
          await http.get<LoanRepaymentOperationDetailsDTO>(
            `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/installment/${operationId}`,
          );
        return loanRepaymentOperationDetails;

      case 'CARD_DEFERRED_CREDIT_FUTURE':
      case 'CARD_DEFERRED_PAYMENT_FUTURE':
        const { data: cardDeferredOperationDetails } =
          await http.get<CardDeferredOperationDetailsDTO>(
            `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/card_payment/${operationId}`,
          );
        return cardDeferredOperationDetails;

      default:
        const { data: operationDetails } = await http.get<OperationDetailsDTO>(
          `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/operations/${operationId}`,
        );
        return operationDetails;
    }
  },
  {
    refetchInterval: (data) => {
      return hasPendingOCR(data as OperationDetailsDTO | undefined)
        ? PENDING_OCR_REFRESH_DELAY_MS
        : isPendingGroupTransfer(data as OperationDetailsDTO | undefined)
          ? PENDING_GROUP_TRANSFER_REFRESH_DELAY_MS
          : false;
    },
  },
);

type UseLoadCurrentAccountOperationsProps = {
  customerId: string;
  page?: number;
  searchParams?: LoadBackendCurrentAccountOperationsParams;
};

export const useLoadCurrentAccountOperations = createInfiniteQueryHook(
  LOAD_CURRENT_ACCOUNT_OPERATIONS,
  async ({ customerId, page = 1, searchParams }: UseLoadCurrentAccountOperationsProps) => {
    if (requiresFlattenOperationsEndpoint(searchParams)) {
      // Use the ElasticSearch endpoint aka with flatten operations
      try {
        const { data } = await http.get<PaginatedList<AccountOperationDTO>>(
          `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/account_operations`,
          {
            // TODO: Remove this hack (convenient for testing)
            params:
              process.env.NODE_ENV === 'development'
                ? canonicalize({ ...searchParams, size: 50, page })
                : canonicalize({ ...searchParams, page }),
          },
        );
        return data;
      } catch (error) {
        const statusCode = getErrorStatus(error);
        const errorKey = getErrorKey(error);
        if (
          statusCode === HTTP_SERVICE_UNAVAILABLE &&
          errorKey === 'operations_full_search_not_available'
        ) {
          useCustomerStore.setState({
            isFullSearchDisabled: true,
          });
        }
        throw error;
      }
    } else {
      // Use the DB endpoint aka including grouped opertions
      const { data } = await http.get<PaginatedList<OperationDTO>>(
        `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/operations`,
        {
          params: canonicalize({ ...searchParams, page }),
          headers: {
            Accept: V2_ACCEPT_HEADER_VALUE,
          },
        },
      );

      return data;
    }
  },
  {
    refetchInterval: (data) => {
      return hasPendingGroupTransfer(data?.pages?.[0]?.results)
        ? PENDING_GROUP_TRANSFER_REFRESH_DELAY_MS
        : false;
    },
  },
);

export const useLoadCustomers = createQueryHook(
  LOAD_CUSTOMERS,
  async () => {
    const { data } = await http.get<WrappedList<AuthenticatedCustomerDTO>>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/mine`,
    );
    return data.results;
  },
  {
    refetchInterval: (customers) =>
      customers?.some(({ products }) =>
        products.some(({ status }) => status === 'WAITING_FOR_CREATION'),
      )
        ? PENDING_PRODUCTS_REFETCH_INTERVAL
        : false,
    staleTime: STALE_TIME_LONG,
  },
);

type UseLoadDocumentFolders = {
  cardId?: string | string[];
  customerId: string;
  documentType?: DocumentFolderType | DocumentFolderType[];
  productId?: string | string[];
};

export const useLoadDocumentFolders = createQueryHook(
  LOAD_DOCUMENT_FOLDERS,
  async ({ cardId, customerId, documentType, productId }: UseLoadDocumentFolders) => {
    const { data } = await http.get<WrappedList<DocumentFolderDTO>>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/document_folders`,
      { params: canonicalize({ cardId, documentType, productId }) },
    );
    return data.results;
  },
);

type UseLoadExchangeRate = {
  customerId: string;
  params: ExchangeRateRequestDTO;
};

export const useLoadExchangeRate = createQueryHook(
  LOAD_EXCHANGE_RATE,
  async ({ customerId, params }: UseLoadExchangeRate) => {
    const { data } = await http.post<ExchangeRateDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/exchange`,
      params,
    );
    return data;
  },
);

type UseLoadGroupOperations = {
  currentAccountId: string;
  customerId: string;
  groupId: string;
  page?: number;
  size?: number;
  status: GroupOperationStatus;
};

export const useLoadGroupOperations = createInfiniteQueryHook(
  LOAD_GROUP_OPERATIONS,
  async ({ currentAccountId, customerId, groupId, page = 1, ...rest }: UseLoadGroupOperations) => {
    const { data } = await http.get<PaginatedList<OperationDTO>>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/group/${groupId}/operations`,
      {
        params: canonicalize({ ...rest, page }),
      },
    );
    return data;
  },
);

type UseLoadGroupTransferStatus = {
  currentAccountId: string;
  customerId: string;
  groupId: string;
};

export const useLoadGroupTransferStatus = createQueryHook(
  LOAD_GROUP_TRANSFER_STATUS,
  async ({ currentAccountId, customerId, groupId }: UseLoadGroupTransferStatus) => {
    const { data } = await http.get<GroupTransferOperationStatusDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/group_transfers/${groupId}/status`,
    );
    return data;
  },
);

type UseLoadInvitation = {
  customerId: string;
};

export const useLoadInvitation = createQueryHook(
  LOAD_INVITATION,
  async ({ customerId }: UseLoadInvitation) => {
    const { data } = await http.get<CurrentUserAccessInvitationDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/users/me/invitation`,
    );
    return data;
  },
);

type UseLoadOverdraftProduct = {
  customerId: string;
  overdraftId: string;
};

export const useLoadOverdraftProduct = createQueryHook(
  LOAD_OVERDRAFT_PRODUCT,
  async ({ customerId, overdraftId }: UseLoadOverdraftProduct) => {
    const { data } = await http.get<OverdraftDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/overdrafts/${overdraftId}`,
    );
    return data;
  },
);

type UseLoadSecondaryIBAN = {
  currentAccountId: string;
  customerId: string;
  secondaryIBANId: string;
};

export const useLoadSecondaryIBAN = createQueryHook(
  LOAD_SECONDARY_IBAN,
  async ({ currentAccountId, customerId, secondaryIBANId }: UseLoadSecondaryIBAN) => {
    const { data } = await http.get<SecondaryIBANDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/ibans/${secondaryIBANId}`,
    );
    return data;
  },
);

type UseLoadSecondaryIBANs = {
  customerId: string;
  params: LoadSecondaryIBANsParams;
};

export const useLoadSecondaryIBANs = createQueryHook(
  LOAD_SECONDARY_IBANS,
  async ({ customerId, params }: UseLoadSecondaryIBANs) => {
    const defaultStatus = [SECONDARY_IBAN_STATUS.ACTIVE, SECONDARY_IBAN_STATUS.INACTIVE];
    const { data } = await http.get<PaginatedList<SecondaryIBANLiteDTO>>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/ibans`,
      { params: canonicalize({ status: defaultStatus, ...params }) },
    );
    return data;
  },
);

export const useLoadSecondaryIBANsForAsyncSelect =
  extendQueryHookForAsyncSelect(useLoadSecondaryIBANs);

type UseLoadTransferStatus = {
  currentAccountId: string;
  customerId: string;
  transferReference: string;
};

export const useLoadTransferStatus = createQueryHook(
  LOAD_TRANSFER_STATUS,
  async ({ currentAccountId, customerId, transferReference }: UseLoadTransferStatus) => {
    const { data } = await http.get<TransferOperationStatusDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/transfers/${transferReference}/status`,
    );
    return data;
  },
);

type UseLoadUpcomingOperations = {
  currentAccountId?: string;
  customerId: string;
  page?: number;
  size?: number;
  skipDateMax?: boolean;
  type?: OperationType | OperationType[];
  typesAlias?: OperationTypesAlias | OperationTypesAlias[];
};

export const useLoadUpcomingOperations = createInfiniteQueryHook(
  LOAD_UPCOMING_OPERATIONS,
  async ({
    currentAccountId,
    customerId,
    page = 1,
    skipDateMax = false,
    ...rest
  }: UseLoadUpcomingOperations) => {
    const dateMax = !!skipDateMax
      ? undefined
      : formatISO(addDays(startOfToday(), UPCOMING_OPERATIONS_MAX_DAYS), {
          representation: 'date',
        });
    const { data } = await http.get<PaginatedList<OperationDTO>>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/upcoming_operations`,
      { params: canonicalize({ dateMax, currentAccountId, ...rest, page }) },
    );
    return data;
  },
  {
    refetchInterval: (data) => {
      return hasPendingGroupTransfer(data?.pages?.[0]?.results)
        ? PENDING_GROUP_TRANSFER_REFRESH_DELAY_MS
        : false;
    },
  },
);

type UseLoadUpcomingOperationsStatistics = {
  currentAccountId?: string;
  customerId: string;
  skipDateMax?: boolean;
  type?: OperationType | OperationType[];
  typesAlias?: OperationTypesAlias | OperationTypesAlias[];
};

export const useLoadUpcomingOperationsStatistics = createQueryHook(
  LOAD_UPCOMING_OPERATIONS_STATISTICS,
  async ({
    currentAccountId,
    customerId,
    skipDateMax = false,
    type,
    typesAlias,
  }: UseLoadUpcomingOperationsStatistics) => {
    const dateMax = !!skipDateMax
      ? undefined
      : formatISO(addDays(startOfToday(), UPCOMING_OPERATIONS_MAX_DAYS), {
          representation: 'date',
        });
    const { data } = await http.get<UpcomingOperationsStatisticsDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/upcoming_operations_statistics`,
      { params: { dateMax, currentAccountId, type, typesAlias } },
    );
    return data;
  },
);

type UseLoadUserAccess = {
  customerId: string;
  userId: string;
};

export const useLoadUserAccess = createQueryHook(
  LOAD_USER_ACCESS,
  async ({ customerId, userId }: UseLoadUserAccess) => {
    const { data } = await http.get<UserAccessDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/users/${userId}`,
    );
    return data;
  },
);

type UseLoadUsers = {
  customerId: string;
};

// FIXME: This endpoint is paginated on the backend. Until we refactor the frontend code
// (the users select), we set a high page size.
export const useLoadUsers = createQueryHook(LOAD_USERS, async ({ customerId }: UseLoadUsers) => {
  const { data } = await http.get<WrappedList<UserAccessLiteDTO>>(
    `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/users`,
    { params: { size: 500 } },
  );
  return data.results;
});

export const useLoadAdmins = extendQueryHook(useLoadUsers, {
  select: (users) =>
    users.filter(
      (user) => user.role === USER_ACCESS_ROLE.ADMIN || user.role === USER_ACCESS_ROLE.OWNER,
    ),
});

/**
 * File actions
 */

type UseCustomerDocumentFileActions = {
  customerId: string;
  documentId: string;
};

export const useCustomerDocumentFileActions = createFileActionsHook(
  async ({ customerId, documentId }: UseCustomerDocumentFileActions) => {
    const { data } = await http.get<Blob>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/documents/${documentId}`,
      { responseType: 'blob' },
    );
    return data;
  },
);

type UseOperationDocumentFileActions = {
  customerId: string;
  documentId: string;
  isTransferRequest?: boolean;
  operationId: string;
} & (
  | {
      // Card operation
      cardId: string;
      currentAccountId?: never;
    }
  | {
      // Account operation
      cardId?: never;
      currentAccountId: string;
    }
);

export const useOperationDocumentFileActions = createFileActionsHook(
  async ({
    cardId,
    currentAccountId,
    customerId,
    documentId,
    isTransferRequest,
    operationId,
  }: UseOperationDocumentFileActions) => {
    const { data } = await http.get<Blob>(
      typeof cardId !== 'undefined'
        ? `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards/${cardId}/operations/${operationId}/documents/${documentId}`
        : isTransferRequest
          ? `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/transfer_requests/${operationId}/documents/${documentId}`
          : `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/operations/${operationId}/documents/${documentId}`,
      { responseType: 'blob' },
    );
    return data;
  },
);

type UseRIBDocumentFileActions = {
  currentAccountId: string;
  customerId: string;
};

export const useRIBDocumentFileActions = createFileActionsHook(
  async ({ currentAccountId, customerId }: UseRIBDocumentFileActions) => {
    const { data } = await http.get<Blob>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/rib`,
      { responseType: 'blob' },
    );
    return data;
  },
);

type UseSecondaryRIBDocumentFileActions = {
  currentAccountId: string;
  customerId: string;
  secondaryIBANId: string;
};

export const useSecondaryRIBDocumentFileActions = createFileActionsHook(
  async ({ currentAccountId, customerId, secondaryIBANId }: UseSecondaryRIBDocumentFileActions) => {
    const { data } = await http.get<Blob>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/ibans/${secondaryIBANId}/rib`,
      { responseType: 'blob' },
    );
    return data;
  },
);

/**
 * Mutations
 */

type UseArchiveCurrentAccountArgs = {
  currentAccountId: string;
  customerId: string;
};

export const useArchiveCurrentAccount = createMutationHook(
  async ({ currentAccountId, customerId }: UseArchiveCurrentAccountArgs) => {
    const { data } = await http.delete(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}`,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks([useLoadAccount, useLoadAccounts], { repeat: 2 }),
  },
);

type UseCancelTransfer = {
  currentAccountId: string;
  customerId: string;
  transferId: string;
};

export const useCancelTransfer = createMutationHook(
  async ({ currentAccountId, customerId, transferId }: UseCancelTransfer) => {
    const { data } = await http.delete(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/transfers/${transferId}`,
    );
    return data;
  },
);

type UseConfirmBeneficiariesImportWith2FA = {
  beneficiaries: CreateBeneficiariesDTO;
  customerId: string;
  headers?: Record<string, string>;
};

export const useConfirmBeneficiariesImportWith2FA = createMutationHookWith2FA(
  async ({ beneficiaries, customerId, headers }: UseConfirmBeneficiariesImportWith2FA) => {
    const { data } = await http.post(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/beneficiaries/batch`,
      beneficiaries,
      { headers },
    );
    return data;
  },
);

type UseCreateAccess = {
  customerId: string;
};

export const useCreateAccess = createMutationHook(
  async ({ customerId }: UseCreateAccess) => {
    const { data } = await http.post(`${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/access`);
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadCustomers),
  },
);

type UseCreateCurrentAccountArgs = {
  customerId: string;
  params: CreateCurrentAccountParamsDTO;
};

export const useCreateCurrentAccount = createMutationHook(
  async ({ customerId, params }: UseCreateCurrentAccountArgs) => {
    const { data } = await http.post<AccountDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts`,
      params,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadAccounts, { repeat: 2 }),
  },
);

type UseCreateSavingAccountArgs = {
  customerId: string;
};

export const useCreateSavingAccount = createMutationHook(
  async ({ customerId }: UseCreateSavingAccountArgs) => {
    const { data } = await http.post<AccountDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/saving_accounts`,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadAccounts, { repeat: 2 }),
  },
);

type UseCreateSecondaryIBAN = {
  currentAccountId: string;
  customerId: string;
  params: CreateSecondaryIBANDTO;
};

export const useCreateSecondaryIBAN = createMutationHook(
  async ({ currentAccountId, customerId, params }: UseCreateSecondaryIBAN) => {
    const { data } = await http.post<CreateSecondaryIBANDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/ibans`,
      params,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadSecondaryIBANs, { repeat: 2 }),
  },
);

type UseDeleteBeneficiary = {
  beneficiaryId: string;
  customerId: string;
};

export const useDeleteBeneficiary = createMutationHook(
  async ({ beneficiaryId, customerId }: UseDeleteBeneficiary) => {
    const { data } = await http.delete<BeneficiaryDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/beneficiaries/${beneficiaryId}`,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks([useLoadBeneficiary, useLoadBeneficiaries]),
  },
);

type UseDeleteSecondaryIBAN = {
  currentAccountId: string;
  customerId: string;
  secondaryIBANId: string;
};

export const useDeleteSecondaryIBAN = createMutationHook(
  async ({ currentAccountId, customerId, secondaryIBANId }: UseDeleteSecondaryIBAN) => {
    const { data } = await http.delete(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/ibans/${secondaryIBANId}`,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadSecondaryIBANs, { repeat: 2 }),
  },
);

type UseMarkBeneficiaryTrustedWith2FA = {
  beneficiaryId: string;
  customerId: string;
  headers?: Record<string, string>;
};

export const useMarkBeneficiaryTrustedWith2FA = createMutationHookWith2FA(
  async ({ beneficiaryId, customerId, headers }: UseMarkBeneficiaryTrustedWith2FA) => {
    const { data } = await http.put(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/beneficiaries/${beneficiaryId}/trusted`,
      undefined,
      { headers },
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks([useLoadBeneficiaries, useLoadBeneficiary]),
  },
);

type UseParseBeneficiariesImportFile = {
  customerId: string;
  file: File;
};

export const useParseBeneficiariesImportFile = createMutationHook(
  async ({ customerId, file }: UseParseBeneficiariesImportFile) => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await http.post<ParsedBeneficiariesDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/beneficiary_files`,
      formData,
    );
    return data;
  },
);

type UsePrepareBeneficiariesImport = {
  beneficiaries: CreateBeneficiariesDTO;
  customerId: string;
};

export const usePrepareBeneficiariesImport = createMutationHook(
  async ({ beneficiaries, customerId }: UsePrepareBeneficiariesImport) => {
    const { data } = await http.post(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/beneficiaries/batch_validation`,
      beneficiaries,
    );
    return data;
  },
);

type UseUnmarkBeneficiaryTrusted = {
  beneficiaryId: string;
  customerId: string;
};

export const useUnmarkBeneficiaryTrusted = createMutationHook(
  async ({ beneficiaryId, customerId }: UseUnmarkBeneficiaryTrusted) => {
    const { data } = await http.delete(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/beneficiaries/${beneficiaryId}/trusted`,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks([useLoadBeneficiaries, useLoadBeneficiary]),
  },
);

type UseUpdateCurrentAccountNameArgs = {
  currentAccountId: string;
  customerId: string;
  params: UpdateCurrentAccountNameParamsDTO;
};

export const useUpdateCurrentAccountName = createMutationHook(
  async ({ currentAccountId, customerId, params }: UseUpdateCurrentAccountNameArgs) => {
    const { data } = await http.put<AccountDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/name`,
      params,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks([useLoadAccount, useLoadAccounts]),
  },
);

type UseUpdateCurrentAccountUsersArgs = {
  currentAccountId: string;
  customerId: string;
  params: UpdateCurrentAccountUsersParamsDTO;
};

export const useUpdateCurrentAccountUsers = createMutationHook(
  async ({ currentAccountId, customerId, params }: UseUpdateCurrentAccountUsersArgs) => {
    const { data } = await http.put<AccountDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/users`,
      params,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks([useLoadAccount, useLoadAccounts]),
  },
);

type UseUpdateCurrentAccountVisibilityArgs = {
  currentAccountId: string;
  customerId: string;
  params: UpdateCurrentAccountVisibilityParamsDTO;
};

export const useUpdateCurrentAccountVisibility = createMutationHook(
  async ({ currentAccountId, customerId, params }: UseUpdateCurrentAccountVisibilityArgs) => {
    const { data } = await http.put<AccountDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/visibility`,
      params,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks([useLoadAccount, useLoadAccounts]),
  },
);

type UseUpdateSecondaryIBAN = {
  currentAccountId: string;
  customerId: string;
  params: UpdateSecondaryIBANParamsDTO;
  secondaryIBANId: string;
};

export const useUpdateSecondaryIBAN = createMutationHook(
  async ({ currentAccountId, customerId, params, secondaryIBANId }: UseUpdateSecondaryIBAN) => {
    const { data } = await http.put(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/current_accounts/${currentAccountId}/ibans/${secondaryIBANId}`,
      params,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks([useLoadSecondaryIBAN, useLoadSecondaryIBANs], { repeat: 2 }),
  },
);
